<template>
  <q-page class="cms-page ql-editor">
    <div class="page-title">
      <div class="page-title--left">
        <h1 v-html="pageTitle" />
        <q-breadcrumbs>
          <q-breadcrumbs-el label="Home" :to="{ name: 'Home' }" />
          <q-breadcrumbs-el :label="pageTitle" />
        </q-breadcrumbs>
      </div>
    </div>
    <div class="container">
      <div class="card-content">
        <div v-html="pageData" v-if="pageData" />
        <LoadMoreSpinner v-else />
      </div>
    </div>
  </q-page>
</template>

<script>
import { mapGetters } from 'vuex'

const legalObjMapper = {
  'review-policy': {
    title: 'Review Policy',
    dataAttr: 'customerReviewPolicy',
  },
  'privacy-policy': {
    title: 'Privacy Policy',
    dataAttr: 'privacyPolicy',
  },
  'refund-policy': {
    title: 'Refund Policy',
    dataAttr: 'refundPolicy',
  },
  'terms-service': {
    title: 'Terms Of Service',
    dataAttr: 'termsOfService',
  },
}
export default {
  computed: {
    ...mapGetters('common', ['clientStoreConfigurationLoaded']),
    pageData() {
      let businessField =
        legalObjMapper[this.$route.params.slugId]?.dataAttr || ''

      if (businessField && this.clientStoreConfigurationLoaded)
        return this.businessConfigurations[businessField] || '-'

      return ''
    },
    pageTitle() {
      return (
        legalObjMapper[this.$route.params.slugId]?.title ||
        this.$route.params.slugId
      )
    },
  },
  meta() {
    return {
      title: this.pageTitle,
      ...this.commonMeta,
    }
  },
  beforeCreate() {
    let slugId = this.$route.params.slugId

    if (!legalObjMapper[slugId])
      this.$router.push({
        name: 'Home',
      })
  },
}
</script>
